import axios from "axios";
import { indonesia_payment } from "../../js/path";

const apiEndpoint = process.env.VUE_APP_INDONESIA_PAYMENT;

export default {
  data() {
    return {
      pageNo: null,
      submitted: false,
      status: false,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      tableData: [],
      payment_gateway_type: [],
      currentPage: 1,
      params: "",
      activeTab: "all",
      key: 0,
      getProduct: [],
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "name",
          label: "Name",
        },
        {
          key: "description",
          label: "Description",
        },
        {
          key: "slug",
          label: "Slug",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "edit",
          label: "Edit",
        },
      ],
      form: {
        id: "",
        name: "",
        description: "",
        slug: "",
        config: null,
        payment_gateway_type_id: null,
        status: false,
        product: null,
      },
    };
  },
  methods: {
    convertToPlainText(htmlString) {
      // Regular expression to replace HTML tags with their corresponding entities
      return htmlString.replace(/<[^>]+>/g, "");
    },

    searchFor() {
      if (this.filter.length > 1) this.fetchData();
      else if (this.filter.length == 0) this.fetchData();
    },

    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData();
        }
      } else if (this.filter.length == 0) this.fetchData();
    },

    filterPage() {
      if (this.pageNo.length > 0) {
        this.currentPage = this.pageNo;
      }
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    changestatus() {
      if (this.status) {
        this.status = false;
      } else {
        this.status = true;
      }
    },

    async fetchProducts() {
      const url = indonesia_payment?.getCouponProducts;
      const res = await this.getRequest(`${apiEndpoint}${url}`);
      if (res.status) {
        this.getProduct = res.response.data;
      }
    },

    async fetchPaymentGatewayType() {
      const url = indonesia_payment?.getwaytypes;
      const data = await this.getRequest(`${apiEndpoint}${url}`);
      if (data.status) {
        this.payment_gateway_type = data.response;
      }
    },

    async fetchData(txt) {
      this.$store.commit("loader/updateStatus", true);
      if (txt == "active") {
        this.activeTab = txt;
      } else if (txt == "in_active") {
        this.activeTab = txt;
      } else if (txt == "all") {
        this.activeTab = txt;
      }
      let request = `${indonesia_payment?.getpaymentGateways}?page=${
        this.currentPage ?? 1
      }&q=${this.filter ?? ""}&filter=${txt ?? ""}`;

      try {
        const res = await axios.get(`${apiEndpoint}${request}`);
        if (res) {
          this.tableData = res?.data?.response;
        }
        this.$store.commit("loader/updateStatus", false);
      } catch (err) {
        this.$store.commit("toast/updateStatus"),
          {
            status: true,
            icon: "error",
            title: "somthing went wrong",
          };
        this.$store.commit("loader.updateStatus", false);
      }
    },

    async updateStatus(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = `${apiEndpoint}${indonesia_payment?.updateStatusGetways}/${id}`;
        const data = await axios.post(url, {
          status: !this.tableData.data[index].status,
        });
        if (data.status) {
          this.tableData.data[index].status =
            !this.tableData.data[index].status;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: "Status updated successfully",
          });
        }
        this.key += 1;
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async submitData() {
      try {
        this.submitted = true;
        this.$v.$touch();

        if (this.$v.$invalid) {
          if (this.form.config == "") {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Please Fill The Required Fields",
            });
            return false;
          } else if (!this.$v.form.config.isValidJSON) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Enter valid json",
            });
            return false;
          } else {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Please Fill The Required Fields",
            });
            return false;
          }
        }

        this.$store.commit("loader/updateStatus", true);

        let dataAppend = {};
        for (var key in this.form) {
          if (key !== "id" && key !== "product") {
            dataAppend[key] = this.form[key];
          }
        }

        let product_data = [];
        for (var k in this.form.product) {
          product_data.push(this.form.product[k].id);
        }
        dataAppend.product_id = product_data;

        if (this.$route.name == "edit-payment-gatways") {
          let url = `${apiEndpoint}${indonesia_payment?.getpaymentGateways}/${this.form.id}`;
          const request = await axios.put(url, dataAppend);
          const response = request?.data;
          if (response?.status) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: response?.message,
            });
            this.$router.push("/payment-gatways");
          }
        } else {
          let url = `${apiEndpoint}${indonesia_payment?.getpaymentGateways}`;
          const request = await axios.post(url, dataAppend);
          const response = request?.data;
          if (response?.status) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: response?.message,
            });
            this.$router.push("/payment-gatways");
          }
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },

    async fetchPaymentGetway(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        const request = `${indonesia_payment?.getpaymentGateways}/${id}`;
        const response = await axios.get(`${apiEndpoint}${request}`);
        const res = response?.data;
        if (res) {
          const responseData = res.response;
          if (responseData.id !== "" && responseData.id !== null) {
            this.form.id = responseData.id;
          }
          if (responseData.name !== "" && responseData.name !== null) {
            this.form.name = responseData.name;
          }
          if (responseData.status !== "" && responseData.status !== null) {
            this.form.status = responseData.status;
          }
          if (
            responseData.description !== "" &&
            responseData.description !== null
          ) {
            this.form.description = responseData.description;
          }
          if (responseData.slug !== "" && responseData.slug !== null) {
            this.form.slug = responseData.slug;
          }
          if (responseData.config !== "" && responseData.config !== null) {
            this.form.config = JSON.stringify(responseData.config);
          }
          if (
            responseData.payment_gateway_type_id !== "" &&
            responseData.payment_gateway_type_id !== null
          ) {
            this.form.payment_gateway_type_id =
              responseData.payment_gateway_type_id;
          }
          if (responseData.status === 1) {
            this.form.status = true;
          }
          if (
            responseData.product_payment_gateways !== "" &&
            responseData.product_payment_gateways !== null
          ) {
            this.form.product = responseData.product_payment_gateways;
          }
        }
        this.$store.commit("loader/updateStatus", false);
      } catch (err) {
        console.error("Error fetching payment gateway:", err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Something went wrong",
        });
        this.$store.commit("loader/updateStatus", false);
      }
    },
  },

  watch: {
    currentPage: {
      handler: function (v) {
        if (v != "" && v != null) {
          this.fetchData(this.activeTab);
          this.pageNo = this.currentPage;
        }
      },
    },
  },
  created() {
    this.fetchPaymentGatewayType();
    if (
      this.$route.name == "add-payment-gatways" ||
      this.$route.name == "edit-payment-gatways"
    ) {
      this.fetchProducts();
      if (this.$route.name == "edit-payment-gatways") {
        this.fetchPaymentGetway(this.$route.params.id);
      }
    } else {
      this.fetchData();
    }
  },
};
